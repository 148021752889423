import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;

// Sentry.init({
//   Vue,
//   dsn: "https://d1673d034dd84ce490dbb3ce6cf6c4ba@o1100797.ingest.sentry.io/6129033",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "zymoscope-3a983e.netlify.app", /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
