import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/vue";
import { Command } from "@/types";
import _ from "lodash";

const AUTH_TOKEN = "apollo-token";
const TB_TOKEN = "tb-token";

Vue.use(Vuex);
Vue.config.devtools = true;
const profile_key = "profile_config";

const api_command = async (command: Command, context: any) => {
  return await axios.post(
    "https://calibration.zymoscope.com/api/commands",
    command,
    context.getters.tb_api_headers
  );
};

export const TELEMETRY_URL = `https://thingsboard.cloud/api/plugins/telemetry`;

export const setSharedAttributes = async (
  id: string,
  attributes: any,
  headers: any
) => {
  const result = await axios.post(
    `${TELEMETRY_URL}/${id}/attributes/SHARED_SCOPE`,
    attributes,
    {
      ...headers,
    }
  );
  return result;
};

const load = (key: string, value: any) =>
  JSON.parse(window.localStorage.getItem(key) ?? JSON.stringify(value));

const save = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));

const loadOktaToken = () => {
  const x = load(AUTH_TOKEN, {});
  if (x.expires_in > +new Date()) {
    return x;
  }
  return null;
};

const loadTBToken = (state: any) => {
  const x = load(TB_TOKEN, { expires_in: 0 });
  if (x && x.expires_in > +new Date()) {
    return x.token;
  } else {
    alert("your session ended, please login again.");
  }
  return null;
};

const tbLogin = async (credentials: any) =>
  await axios.post(
    "https://zymoscope-3a983e.netlify.app/api/login",
    credentials
  );

const default_user = {
  id: {
    entityType: null,
    id: null,
  },
  createdTime: null,
  additionalInfo: {},
  email: null,
  authority: null,
  firstName: null,
  lastName: null,
  name: null,
  ownerId: {},
};

const default_profiles = [
  {
    name: "some test profile",
    definition: "flowrate,duration\n0,2\n10,2",
    dump: [0, 0, 10, 10],
  },
];

export default new Vuex.Store({
  state: {
    api_token: null,
    okta_token: loadOktaToken(),
    user: load("user", default_user),
    loading: false,
    production: true,
    start: 0,
    loader: 0 as any,
    status: [{ ts: 0, value: "---" }],
    progress: [
      { ts: 0, value: 1 },
      { ts: 0, value: 1 },
    ],
    error: null as any,
    done: false,
    executions: [] as any[],
    condensation_experiments: [] as any[],
    profiles: [] as any[],
    tb_auth: { username: null, password: null },
    models: [] as any[],
  },
  getters: {
    tb_api_token(state) {
      state.api_token = loadTBToken(state);
      return state.api_token;
    },
    tb_api_headers(state) {
      return {
        headers: {
          "X-Authorization": loadTBToken(state),
        },
      };
    },
  },
  mutations: {
    update_tb_auth(state, { username, password }) {
      state.tb_auth.password = password;
      state.tb_auth.username = username;
    },
    update_api_token(state, { token, expires_in }) {
      save("api_token", token);
      save(TB_TOKEN, { token, expires_in });
      state.api_token = token;
    },
    update_user(state, payload) {
      window.localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
  },
  actions: {
    login: async (context, { username, password }) => {
      context.commit("update_tb_auth", { username, password });
      try {
        const result = await tbLogin({ username, password });
        context.state.error = null;
        const token = "Bearer " + result?.data?.token.token;
        const decoded: any = jwt_decode(result?.data?.token.token);
        window.console.log(decoded);
        const refresh = result?.data?.token.refreshToken;
        window.console.log(refresh);
        Sentry.setUser({ email: username });
        context.commit("update_user", result?.data.user);
        context.commit("update_api_token", {
          token: token,
          expires_in: decoded.exp * 1000,
        });
        context.dispatch("load_assets");
      } catch (error) {
        context.state.error = error;
      }
    },
    load_calibration_models: async (context, payload) => {
      const models = await api_command(
        { command: "load_calibration_models", payload: {} },
        context
      );
      context.state.models = models.data.meta.map((x: any, i: number) => ({
        ...x,
        id: x.id ? x.id : i,
      }));
    },
    load_profiles: async (context, payload) => {
      context.state.loading = true;
      try {
        const profiles = await api_command(
          { command: "load_profiles", payload: {} },
          context
        );
        context.state.profiles = profiles?.data?.profiles ?? [];
        context.state.executions = profiles?.data?.executions ?? [];

        context.state.condensation_experiments = _.orderBy(
          context.state.executions.filter(
            (x: any) => x.metadata?.category == "condensation"
          ),
          "begin",
          "desc"
        );
      } catch (error) {
        console.error(error);
      }
      context.state.loading = false;
    },

    load_executions: async (context, { profile }) => {
      context.state.loading = true;
      try {
        const result = await api_command(
          { command: "load_executions", payload: { profile } },
          context
        );
        context.state.executions = result?.data?.executions ?? [];

        context.state.condensation_experiments = _.orderBy(
          context.state.executions.filter(
            (x: any) => x.metadata?.category == "condensation"
          ),
          "begin",
          "desc"
        );
      } catch (error) {
        console.error(error);
      }
      context.state.loading = false;
    },
    update_profile: async (context, payload) => {
      context.state.loading = true;
      try {
        const result = await api_command(
          { command: "update_profile", payload },
          context
        );
        context.dispatch("load_profiles");
      } catch (error) {
        console.error(error);
      }
      context.state.loading = false;
    },
    async addProfile(context, profile) {
      const command: Command = {
        command: "add_profile",
        payload: {
          ...profile,
          meta: {},
          created: {
            time: +new Date(),
            by: context.state.user.email,
          },
        },
      };

      context.state.profiles.push(profile);
      const update = await api_command(command, context);
      this.dispatch("load_profiles");
    },
    async delete_profile_from_db(context, { profile }) {
      const index = context.state.profiles.indexOf(profile);
      context.state.profiles.splice(index, 1);
      const command: Command = {
        command: "delete_profile",
        payload: { id: profile._id },
      };
      const update = await api_command(command, context);

      this.dispatch("load_profiles");
      context.dispatch("load_profiles");
    },

    async execute_profile(context, { profile }) {
      const begin = +new Date();
      const end = begin + profile.dump.length * 1000 + 120 * 1000;
      const command: Command = {
        command: "add_profile_execution",
        payload: {
          profile_id: profile._id,
          profile: { ...profile, dump: null },
          ...profile.model,
          begin: begin,
          end: end,
          created: {
            time: begin,
            by: context.state.user.email,
          },
        },
      };

      const update = await api_command(command, context);

      const flow_r = await setSharedAttributes(
        "DEVICE/" + profile.model.mfc.id,
        {
          profile: profile?.definition?.replace("setpoint", "flowrate"),
          profile_id: profile._id,
          profile_name: profile.name,
          profile_start: begin,
        },
        {
          headers: {
            "X-Authorization": context.state.api_token,
          } as any,
        }
      );
      context.dispatch("load_profiles");
    },
    // async delete_profile_execution_from_db(context, { profile }) {
    //   const index = context.state.profiles.indexOf(profile);
    //   context.state.profiles.splice(index, 1);
    //   const command: Command = {
    //     command: "delete_profile_execution",
    //     payload: { id: profile._id },
    //   };
    //   const update = await api_command(command, context);

    //   this.dispatch("load_profiles");
    //   context.dispatch("load_profiles");
    // },
    async cancel_profile_execution(context, { profile }) {
      const begin = +new Date();

      const flow_r = await setSharedAttributes(
        "DEVICE/" +
          (profile?.model?.mfc?.id
            ? profile.model?.mfc?.id
            : "67a65640-d354-11eb-ae0e-1f8899a6f9b3"), // backwards compatibility for old setup.
        {
          profile: "setpoint,duration,ramp_up_period\n0,0,0", // TODO: remove when migrated away from old setup.
          profile_config: "cancel",
          profile_id: profile._id,
          profile_name: profile.name,
          profile_start: begin,
        },
        {
          headers: {
            "X-Authorization": context.state.api_token,
          } as any,
        }
      );
      context.dispatch("load_profiles");
    },
    async execute_condensation_profile(context, { profile }) {
      const begin = +new Date();
      const end = begin + profile.duration.ms * 1000;
      const command: Command = {
        command: "add_condensation_profile_execution",
        payload: {
          flow_profile_id: profile.flow_profile._id,
          chiller_profile_id: profile.chiller_profile._id,
          name: profile.name,
          metadata: { ...profile },
          begin: begin,
          end: end,
          created: {
            time: begin,
            by: context.state.user.email,
          },
        },
      };

      const update = await api_command(command, context);
      const meta = update.data.meta;
      const chiller_payload = {
        name: meta.name,
        id: meta.id,
        profile: profile.chiller_profile.profile,
      };
      const mfc_payload = {
        name: meta.name,
        id: meta.id,
        profile: profile.flow_profile.profile,
      };
      console.warn("profile_saved", update.data);
      context.dispatch("load_profiles");
      const chiller_r = await setSharedAttributes(
        "DEVICE/" + profile.chiller.id,
        { [profile_key]: chiller_payload },
        {
          headers: {
            "X-Authorization": context.state.api_token,
          } as any,
        }
      );
      console.warn("chiller", chiller_r);
      const flow_r = await setSharedAttributes(
        "DEVICE/" + profile.mfc.id,
        {
          ["profile"]: profile.flow_profile?.definition?.replace(
            "setpoint",
            "flowrate"
          ),
          [profile_key]: mfc_payload,
          profile_id: meta.id,
          profile_name: meta.name,
          profile_start: meta.begin,
        },
        {
          headers: {
            "X-Authorization": context.state.api_token,
          } as any,
        }
      );
      console.warn("mfc", flow_r);
    },
    async run(context, index) {
      context.dispatch("stopLoading");

      const profile = context.state.profiles[index];
      const begin = +new Date();
      const end = begin + profile.dump.length * 1000;
      const command: Command = {
        command: "add_profile_execution",
        payload: {
          profile_id: profile._id,
          profile: { ...profile, dump: null },
          begin: begin,
          end: end,
          created: {
            time: begin,
            by: context.state.user.email,
          },
          log: [{ ts: begin, value: "waiting for device " }],
        },
      };

      const update = await api_command(command, context);

      context.state.start = +new Date();
      context.state.status = [{ ts: 0, value: "waiting for device " }];

      axios.post(
        context.state.production
          ? "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/67a65640-d354-11eb-ae0e-1f8899a6f9b3/SHARED_SCOPE" // prod
          : "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/e68e13e0-faa9-11eb-ab24-1f8899a6f9b3/SHARED_SCOPE", // TEST
        {
          profile: context.state.profiles[index]?.definition?.replace(
            "setpoint",
            "flowrate"
          ),
          profile_id: update.data.meta.id,
          profile_name: context.state.profiles[index].name,
          profile_start: context.state.start,
          profile_end: null,
        },
        {
          headers: {
            "X-Authorization": context.state.api_token,
          } as any,
        }
      );

      context.dispatch("startLoading", { id: update.data.meta.id });
    },
    async startLoading(context, { id }) {
      context.dispatch("stopLoading");

      context.state.loader = setInterval(() => {
        axios
          .get(
            (context.state.production
              ? "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/67a65640-d354-11eb-ae0e-1f8899a6f9b3/values/timeseries?keys=message,alicat_flowrate_t_avg,zymometer_flowrate_t_avg,alicat_flowrate_avg,zymometer_flowrate_avg,&startTs="
              : "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/e68e13e0-faa9-11eb-ab24-1f8899a6f9b3/values/timeseries?keys=message,alicat_flowrate_t_avg,zymometer_flowrate_t_avg,alicat_flowrate_avg,zymometer_flowrate_avg,&startTs=") +
              context.state.start +
              "&endTs=" +
              +new Date() +
              "&interval=60000&limit=3600&agg=NONE",
            {
              headers: {
                "X-Authorization": context.state.api_token,
              } as any,
            }
          )
          .then(async (x) => {
            window.console.log(x.data);
            context.state.status = x.data?.message ?? [
              { ts: 0, value: "waiting for device " },
            ];
            context.state.progress = x.data?.zymometer_flowrate_t_avg ?? [];
            if (context.state.status.map((x) => x.value).indexOf("Done") > -1) {
              context.dispatch("stopLoading");

              if (id) {
                await api_command(
                  {
                    command: "update_profile_execution_log",
                    payload: {
                      query: { id },
                      changes: { log: [...context.state.status] },
                    },
                  },
                  context
                );
              }
            }
          });
      }, 1000);
    },
    stopLoading(context) {
      clearInterval(context.state.loader);
    },
    reconnect(context) {
      clearInterval(context.state.loader);
      axios
        .get(
          context.state.production
            ? "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/67a65640-d354-11eb-ae0e-1f8899a6f9b3/values/attributes" // PROD
            : "https://thingsboard.cloud/api/plugins/telemetry/DEVICE/e68e13e0-faa9-11eb-ab24-1f8899a6f9b3/values/attributes", // TEST
          {
            params: { keys: "profile_start" },
            headers: {
              "X-Authorization": context.state.api_token,
            } as any,
          }
        )
        .then((x) => {
          window.console.log("attr", x.data[0].value);
          context.state.start = parseInt(x.data[0].value);
          context.dispatch("startLoading");
        });
    },
  },
  modules: {},
});
