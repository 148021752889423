import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Calibration.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Profiles",
    component: () => import("../views/Profiles.vue"),
  },
  {
    path: "/profiles",
    name: "Profiles",
    component: () => import("../views/Profiles.vue"),
  },
  {
    path: "/models",
    name: "Model",
    component: () => import("../views/Define.vue"),
  },
  {
    path: "/config",
    name: "Config",
    component: () => import("../views/Config.vue"),
  },
  {
    path: "/banks",
    name: "Banks",
    component: () => import("../views/Banks.vue"),
  },
  {
    path: "/condensation",
    name: "Condensation",
    component: () => import("../views/Condensation.vue"),
  },
  {
    path: "/calibration",
    name: "Calibration",
    component: () => import("../views/Calibration.vue"),

    children: [
      {
        path: ":start/:end",
        name: "CalibrationTime",
        component: () => import("../views/Calibration.vue"),
      },
    ],
  },
  {
    path: "/raspberry-setup",
    name: "raspberry-setup",
    component: () => import("../views/Calibration.vue"),

    children: [
      {
        path: ":start/:end",
        name: "CalibrationTime",
        component: () => import("../views/Calibration.vue"),
      },
    ],
  },
  {
    path: "/login/callback",
    name: "callback",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Callback.vue"),
  },
  {
    path: "/token",
    name: "token",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Token.vue"),
  },
  {
    path: "/migrate",
    name: "migrate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Migrate.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
